import React, { useState } from "react";
import circelFlag from "../assets/images/svg/circel-flag.svg";
import {
  MEMBERSHIP_ADVANCED_DATA_LIST,
  MEMBERSHIP_BASIC_DATA_LIST,
} from "../utils/helper";
import Paragraph from "./common/Paragraph";
import PrimaryButton from "./common/PrimaryButton";

const PricingMembership = () => {
  const [active, setActive] = useState("basic");
  const TOPFIVE_BASIC = MEMBERSHIP_BASIC_DATA_LIST.slice(0, 5);
  const REAMAININGFIVE_BASIC = MEMBERSHIP_BASIC_DATA_LIST.slice(5);
  const TOPSEVEN_ADVANCED = MEMBERSHIP_ADVANCED_DATA_LIST.slice(0, 7);
  const REAMAININGSEVEN_ADVANCED = MEMBERSHIP_ADVANCED_DATA_LIST.slice(7);

  return (
    <div data-aos="fade-left" className="relative w-full z-10">
      <div className="bg-white rounded-xl md:rounded-2xl lg:rounded-3xl max-w-[981px] h-full w-full shadow-black-project px-4 py-6 md:py-8 lg:p-6 2xl:px-[55px] 2xl:pr-[127px] xl:pt-[49px] xl:pb-[61px]">
        <h3 className="!leading-127 text-black font-bold text-2xl md:text-3xl lg:text-4xl xl:text-custum-2md">
          Memberships
        </h3>
        <div className="flex bg-black max-w-[264px] p-[3px] rounded-[36px] md:rounded-[50px] my-6 lg:mt-8">
          <div
            className={`relative z-10 after:absolute after:duration-300 flex w-full after:left-0 after:top-0 after:h-full after:bg-antique-brass after:-z-10 overflow-hidden after:max-w-[130px] after:w-full after:overflow-hidden after:rounded-full ${active === "advanced" && "after:left-[128px]"
              }`}
          >
            <div
              onClick={() => setActive("basic")}
              className={`max-w-[130px] w-full cursor-pointer rounded-[36px] md:rounded-[50px] py-[6.58px]  ${active === "basic" ? "after:left-0" : " after:left-[-100px]"
                }`}
            >
              <Paragraph bold white>
                Basic
              </Paragraph>
            </div>
            <div
              onClick={() => setActive("advanced")}
              className={`max-w-[130px] w-full cursor-pointer rounded-[36px] md:rounded-[50px] py-[6.58px] ${active === "advanced" ? "bg--brass" : ""
                }`}
            >
              <Paragraph bold white>
                Advanced
              </Paragraph>
            </div>
          </div>
        </div>
        {active === "basic" && (
          <div>
            <h4 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold !leading-170">
              $600/m
            </h4>
            <Paragraph medium left className={"!leading-none"}>
            Pause anytime
            </Paragraph>
            <div className="my-6 md:my-8 lg:mt-[35px] lg:mb-[38px] w-full h-[1px] bg-black"></div>
            <Paragraph small bold left>
              Whats included
            </Paragraph>
            <div className="flex flex-col sm:flex-row lg:flex-col xl:flex-row gap-0.5 sm:gap-6 lg:gap-0.5 xl:gap-4 2xl:gap-[56px] py-5 md:pb-8 lg:pb-11">
              <div className="flex flex-col gap-0.5">
                {TOPFIVE_BASIC.map((obj, i) => (
                  <Paragraph
                    key={i}
                    left
                    medium
                    className={"flex items-start gap-2 !leading-200"}
                  >
                    <span className="w-1 h-1 block rounded-full bg-black translate-y-3.5 lg:translate-y-4"></span>
                    {obj}
                  </Paragraph>
                ))}
              </div>

              <div className="flex flex-col gap-0.5">
                {REAMAININGFIVE_BASIC.map((obj, i) => (
                  <Paragraph
                    key={i}
                    left
                    medium
                    className={`flex items-start gap-2 !leading-200 ${REAMAININGFIVE_BASIC.length - 1 === i &&
                      "!leading-145 !gap-[1px]"
                      }`}
                  >
                    {REAMAININGFIVE_BASIC.length - 1 === i && <span>*</span>}
                    <span
                      className={`w-1 h-1 block rounded-full bg-black translate-y-3.5 lg:translate-y-4 ${REAMAININGFIVE_BASIC.length - 1 === i && "hidden"
                        }`}
                    ></span>
                    {obj}
                  </Paragraph>
                ))}
              </div>
            </div>
          </div>
        )}
        {active === "advanced" && (
          <div>
            <h4 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold !leading-170">
              $750+/m
            </h4>
            <Paragraph medium left className={"!leading-none"}>
            Pause anytime
            </Paragraph>
            <div className="my-6 md:my-8 lg:mt-[35px] lg:mb-[38px] w-full h-[1px] bg-black"></div>
            <Paragraph small bold left>
              Whats included*
            </Paragraph>
            <div className="flex flex-col sm:flex-row lg:flex-col xl:flex-row gap-0.5 sm:gap-6 lg:gap-0.5 xl:gap-4 2xl:gap-[56px] py-5 md:pb-8 lg:pb-11">
              <div className="flex flex-col gap-0.5">
                {TOPSEVEN_ADVANCED.map((obj, i) => (
                  <Paragraph
                    key={i}
                    left
                    medium
                    className={"flex items-start gap-2 !leading-200"}
                  >
                    <span className="w-1 h-1 block rounded-full bg-black translate-y-3.5 lg:translate-y-4"></span>
                    {obj}
                  </Paragraph>
                ))}
              </div>

              <div className="flex flex-col gap-0.5">
                {REAMAININGSEVEN_ADVANCED.map((obj, i) => (
                  <Paragraph
                    key={i}
                    left
                    medium
                    className={`flex items-start gap-2 !leading-200 ${REAMAININGSEVEN_ADVANCED.length - 1 === i &&
                      "!leading-145 gap-[1px]"
                      }`}
                  >
                    {REAMAININGSEVEN_ADVANCED.length - 1 === i && (
                      <span>*</span>
                    )}
                    <span
                      className={`w-1 h-1 block rounded-full bg-black translate-y-3.5 lg:translate-y-4 ${REAMAININGSEVEN_ADVANCED.length - 1 === i && "hidden"
                        }`}
                    ></span>
                    {obj}
                  </Paragraph>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className="mb-4 xl:mb-0">
          <PrimaryButton url={"https://calendly.com/aaronhandford/15-minute-intro-info-call"} target="_blank"
            left
            className={
              "xl:!max-w-[276px] xl:w-full xl:py-[30.2px] text-center lg:!text-custum-base !font-bold !leading-130"
            }
          >
            Book a call
          </PrimaryButton>
        </div>
      </div>
      <img
        loading="lazy"
        src={circelFlag}
        alt="circelFlag"
        className="w-full h-full 2xl:max-w-[589px] lg:block hidden max-w-[400px] max-h-[400px] 2xl:max-h-[595px] absolute top-[-8%] xl:top-[-10%] 2xl:-top-[15%] pointer-events-none animate-spin-slow -right-[20%] 2xl:-right-[27%] -z-10"
        width={589}
        height={595}
      />
    </div>
  );
};

export default PricingMembership;
