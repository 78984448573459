import React from "react";
import { useScrollHandlers } from "../utils/UseScrollHandler";
import { BENEFITS_LINK } from "../utils/constants";
import { MEMBERSHIP_BENEFITS } from "../utils/helper";
import { useGlobalInfoProvider } from "./common/GlobalProvider";
import SubHeading from "./common/SubHeading";
import Lottie from "lottie-react";
import Paragraph from "./common/Paragraph";
const MembershipBenefits = () => {
  const { setHandleActiveTag } = useGlobalInfoProvider();
  useScrollHandlers({
    elementId: BENEFITS_LINK,
    setHandleActiveTag,
  });
  return (
    <div
      id="benefits"
      className="xl:pt-[109px] xl:pb-[145px] lg:py-24 md:py-20 sm:py-16 py-12 bg-light-black rounded-2xl sm:rounded-36"
    >
      <div className="max-w-[1400px] mx-auto px-4 sm:px-7 md:px-9 lg:px-20">
        <SubHeading data-aos="fade-up">Membership Benefits</SubHeading>
        <Paragraph
          data-aos-delay="200"
          data-aos="fade-up"
          big
          medium
          className={"pt-5 md:pt-[21px] max-w-[899px]"}
        >
          Web support so good you'll never need to go anywhere else for coding,
          maintenance, integrations, or security. Seriously.
        </Paragraph>
        <div className="xl:mt-20 lg:mt-16 md:mt-14 sm:mt-12 mt-8 grid lg:grid-cols-3 sm:grid-cols-2 gap-7 2xl:gap-x-6 2xl:gap-y-20">
          {MEMBERSHIP_BENEFITS.map((obj, i) => {
            return (
              <div
                data-aos="fade-up"
                data-aos-delay="200"
                key={i}
                className="max-w-[340px] flex flex-col  items-center mx-auto"
              >
                <div className="md:mb-6 sm:h-[84px] md:h-28 xl:h-[158px] flex items-center mb-4">
                  <div className="w-[132px] h-[132px] block overflow-hidden relative">
                    <div className="md:w-[162px] md:h-[162px] w-[132px] h-[132px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                      <Lottie
                        className="md:w-[162px] md:h-[162px] w-[132px] h-[132px]"
                        animationData={obj.image}
                        loop={true}
                        autoplay={true}
                      />
                    </div>
                  </div>
                </div>
                <h3 className="font-bold text-black !leading-130 lg:text-custum-md md:text-2xl sm:text-xl text-lg text-center">
                  {obj.title}
                </h3>
                <p className="font-normal text-black !leading-145 lg:text-lg sm:text-base text-sm md:mt-4 mt-2 text-center">
                  {obj.description}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MembershipBenefits;
