import Lottie from "lottie-react";
import React from "react";
import cirleAnimation from "../assets/images/svg/circle-animation.svg";
import growthAnimation from "../assets/images/svg/growth-animation.svg";
import logoAnimation from "../json/logo-animation.json";
import { HERO_LINK } from "../utils/constants";
import { useScrollHandlers } from "../utils/UseScrollHandler";
import { useGlobalInfoProvider } from "./common/GlobalProvider";
import Paragraph from "./common/Paragraph";
import PrimaryButton from "./common/PrimaryButton";
const Hero = () => {
  const { setHandleActiveTag } = useGlobalInfoProvider();
  useScrollHandlers({
    elementId: HERO_LINK,
    setHandleActiveTag,
  });
  return (
    <div id="hero" className="max-sm:min-h-[545px]">
      <div className="flex justify-center items-center py-[19px] px-4 bg-white">
        <a href="/" className="flex gap-2 items-center">
          <div className="md:w-[53px] w-10 md:h-[53px] h-10 overflow-hidden relative">
            <div className="md:w-[135px] w-[100px] h-[100px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rotate-180 md:h-[135px]">
              <Lottie
                className="w-full h-full"
                animationData={logoAnimation}
                loop={false}
              />
            </div>
          </div>
          <p className="font-poppins font-bold text-lg md:text-xl lg:text-custum-base !leading-145">
            Floating World
          </p>
        </a>
      </div>
      <div className="xl:pt-[146px] lg:pt-28 lg:pb-[131px] bg-light-black rounded-t-2xl sm:rounded-t-36 py-24 md:py-28 px-4 relative">
        <img
          src={cirleAnimation}
          alt="circle-animation"
          className="max-h-[150px] md:max-h-[200px] z-10 lg:max-h-[280px] xl:max-h-[300px] 2xl:max-h-[422px] absolute top-[60%] lg:top-[116px] -left-10 lg:-left-[109px] xl:left-0 pointer-events-none"
        />
        <img
          src={growthAnimation}
          alt="growth"
          className="max-h-[150px] md:max-h-[180px] z-10 lg:max-h-[280px] 2xl:max-h-[358px] absolute bottom-[40px] lg:bottom-[80px] -right-10 xl:right-0 pointer-events-none"
        />
        <div className="relative z-20">
          <h1
            data-aos="fade-up"
            className="max-w-[500px] lg:max-w-[826px] text-5xl md:text-custum-lg xl:text-custum-xl font-bold !leading-91 text-center mx-auto pb-6 md:pb-[27px]"
          >
           Scale and grow with ease
          </h1>
          <Paragraph
            data-aos-delay="100"
            data-aos="fade-up"
            medium
            big
            className={"pb-6 md:pb-8 lg:pb-[38px] max-w-[780px]"}
          >
          Monthly website support & coding packages to maximize your digital presence.
          </Paragraph>
          <div data-aos-delay="300" data-aos="fade-up">
            <PrimaryButton
              url="#memberships"
              className=" mx-auto !flex max-w-max"
            >
              See Plans
            </PrimaryButton>
          </div>
          <Paragraph
            data-aos-delay="800"
            data-aos="fade-up"
            medium
            className={
              "!text-base flex justify-center gap-2 items-center pt-[21px]"
            }
          >
            <span className="w-4 h-4 block rounded-full animate-dot bg-red"></span>
            Available now
          </Paragraph>
        </div>
      </div>
    </div>
  );
};

export default Hero;
