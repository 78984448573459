import React from "react";
import { useScrollHandlers } from "../utils/UseScrollHandler";
import { HOW_IT_WORKS_LINK } from "../utils/constants";
import { WEB_SUPPORTED_LIST } from "../utils/helper";
import { useGlobalInfoProvider } from "./common/GlobalProvider";
import Paragraph from "./common/Paragraph";
import PrimaryButton from "./common/PrimaryButton";
import SubHeading from "./common/SubHeading";
import Lottie from "lottie-react";
const WebSupport = () => {
  const { setHandleActiveTag } = useGlobalInfoProvider();
  useScrollHandlers({
    elementId: HOW_IT_WORKS_LINK,
    setHandleActiveTag,
  });
  return (
    <div
      id="how_it_work"
      className="bg-light-black rounded-b-2xl sm:rounded-b-36"
    >
      <div className="pb-12 md:pb-16 lg:pb-20 pt-16 md:pt-24 lg:pt-32 xl:pt-[142px] xl:pb-[176px] mx-auto max-w-[1380px]  px-4 sm:px-7 md:px-9 lg:px-20">
        <SubHeading data-aos="fade-up" className={"max-w-[880px]"}>
          We didn’t reinvent the wheel. Just web support
        </SubHeading>
        <Paragraph
          data-aos-delay="200"
          data-aos="fade-up"
          big
          medium
          className={"pt-5 md:pt-[21px] pb-10 md:pb-12 lg:pb-16 xl:pb-[68px]"}
        >
          Development, Support, & AI Integration like you want it
        </Paragraph>
        <div
          data-aos="fade-up"
          data-aos-delay="300"
          className="grid  lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6 xl:gap-16 2xl:gap-28"
        >
          {WEB_SUPPORTED_LIST.map((obj, i) => {
            return (
              <div
                key={i}
                className={`flex flex-col gap-4 lg:gap-6`}
              >
                <div className="mx-auto">
                  <div className="w-[132px] h-[132px] block overflow-hidden relative">
                    <div className="md:w-[162px] md:h-[162px] w-[132px] h-[132px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                      <Lottie
                        className="md:w-[162px] md:h-[162px] w-[132px] h-[132px]"
                        animationData={obj.image}
                        loop={true}
                        autoplay={true}
                      />
                    </div>
                  </div>
                </div>
                <Paragraph
                  small
                  medium
                  className="!leading-145 md:!text-base lg:!text-lg xl:!text-custum-base"
                >
                  {obj.description}
                </Paragraph>
              </div>
            );
          })}
        </div>
        <div className="pt-6 md:pt-10 lg:pt-16 xl:pt-[85px]">
          <PrimaryButton
            data-aos="fade-up"
            data-aos-delay="400"
            url="#memberships"
            className=" mx-auto !flex max-w-max"
          >
            See Plans
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default WebSupport;
